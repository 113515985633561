import React from "react";

const CustomCheckbox = ({ checked, onChange, label }: any) => {
  return (
    <label className="inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        className="hidden"
        checked={checked}
        onChange={onChange}
      />
      <div
        className={`w-6 h-6 border-2 rounded ${
          checked ? "bg-olive" : "border-olive"
        } flex items-center justify-center`}
      >
        {checked && (
          <svg
            className="w-4 h-4 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
        )}
      </div>
      {label && <span className="ml-2 text-sm font-light">{label}</span>}
    </label>
  );
};

export default CustomCheckbox;

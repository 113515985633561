import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import CrossIcon from "../../assets/common/cross.svg";
import Logo from "../../assets/common/logo.png";
import ArrowIcon from "../../assets/common/arrow_right.svg";

import RectangularButton from "../inputs/rectangularButton";

const AuthPassword = ({
  password,
  setPassword,
  onClose,
  onNext,
  onBack,
  onSignUp,
}: any) => {
  const { t } = useTranslation();

  return (
    <div>
      <button
        className="absolute top-8 left-8 text-gray-500 hover:text-gray-700 rotate-180"
        onClick={onBack}
      >
        <img width={12} height={10} src={ArrowIcon} alt="CrossIcon" />
      </button>
      <button
        className="absolute top-8 right-8 text-gray-500 hover:text-gray-700"
        onClick={onClose}
      >
        <img width={15} height={15} src={CrossIcon} alt="CrossIcon" />
      </button>
      <div className="w-full flex flex-col items-center justify-center px-6">
        <img src={Logo} alt="Logo" width={80} height={80} />
        <h1 className="text-2xl font-bold text-olive">
          {t("Enter your password")}
        </h1>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder={t("Your password...")}
          className="font-light px-2 py-2 rounded-md border border-gray-300 w-full mt-4"
          style={{ fontSize: "13px" }}
        />
        <div className="w-1/3 flex items-center justify-center mt-4 mb-20">
          <RectangularButton title="Next" onClick={onNext} />
        </div>

        <p className="font-bold text-sm mx-4 text-center mt-4">
          {t("No account yet?")}{" "}
          <span
            className="text-olive underline cursor-pointer"
            onClick={onSignUp}
          >
            {t("Sign up")}
          </span>
        </p>
      </div>
    </div>
  );
};

export default AuthPassword;

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import useRedirect from "../../hooks/useRedirect";
import { GlobalContext } from "../../context/GlobalContext";

import ProfilePicture from "../../assets/Profile.svg";
import ProfileBluePicture from "../../assets/common/user_blue.svg";

import RelativeTime from "../common/relativeTime";

function cropString(str: string, maxLength: number = 40): string {
  if (str.length > maxLength) {
    return str.substring(0, maxLength - 3) + "...";
  }
  return str;
}

const MeduimArticleCard = ({ article }: any) => {
  const { t } = useTranslation();
  const redirectTo = useRedirect();
  const globalContext = useContext(GlobalContext);

  const { setCurrentArticle } = globalContext;
  if (article === undefined) return null;
  return (
    <button
      className="w-full flex flex-row w-full max-h-40 text-start"
      onClick={() => {
        redirectTo(`/article/${article._id}`);
        setCurrentArticle(article);
      }}
    >
      <img
        className="rounded-lg shadow-sm w-1/2 mr-4 max-h-full h-full object-cover"
        src={article.previewImage}
        alt={`${article.title}Image`}
      />
      <div className="flex flex-col justify-between h-full w-full">
        <h1 className="font-bold text-lg">{cropString(article.title)}</h1>
        <div className="flex flex-row items-center justify-between">
          <div
            className="flex flex-row items-center text-zinc-500"
            style={{ fontSize: "10px" }}
          >
            <img
              className="rounded-full h-6 w-6 bg-zinc-200 p-1"
              src={ProfileBluePicture}
              alt="AuthorPicture"
            />
            <span className="mx-2 text-black">{article.author}</span>
            <RelativeTime date={article.createdAt} />
          </div>
          <span className="text-sm" style={{ fontSize: "10px" }}>
            {article.createdAt.toLocaleDateString("de-DE")}
          </span>
        </div>
      </div>
    </button>
  );
};

export default MeduimArticleCard;

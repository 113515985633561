import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import useRedirect from "../../hooks/useRedirect";
import { GlobalContext } from "../../context/GlobalContext";

import AdminCheckbox from "../inputs/adminCheckbox";
import AdminPanelButton from "../inputs/AdminPanelButton";

import PencilBlueIcon from "../../assets/admin/pencil_blue.svg";

function stripHtmlTags(htmlString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  return doc.body.textContent || "";
}

const AdminArticleCard = ({ article, isSelected, toggleSelect }: any) => {
  const { t } = useTranslation();
  const redirectTo = useRedirect();
  const globalContext = useContext(GlobalContext);

  const [isPulished, setIsPublished] = useState(article.isPublished);

  const { setCurrentArticle, PUBLIC_API_URL } = globalContext;

  const toogleIsPublished = async () => {
    const token = localStorage.getItem("token");
    const newsData = {
      isPulished: false,
    };

    try {
      const response = await fetch(
        `${PUBLIC_API_URL}/news/${article._id}/${isPulished ? "hide" : "show"}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          // body: JSON.stringify(newsData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setIsPublished(!isPulished);
        console.log("Toggled is published success ", data);
      } else {
        const errorData = await response.json();
        console.error("Error toggling 'isPublished' news:", errorData);
      }
    } catch (error) {
      console.error("Internal error:", error);
    }
  };

  const handleCropContent = (content: string, limit: number) => {
    if (content.length > limit) {
      return content.substring(0, limit) + "...";
    }
    return content;
  };

  return (
    <div className="w-full flex flex-row items-center border border-zinc-300 rounded-2xl p-4">
      <div className="mx-checkbox_offset">
        <AdminCheckbox checked={isSelected} onChange={toggleSelect} />
      </div>
      <div className="w-3/6 flex flex-row">
        <img
          className="h-full max-h-20 rounded-lg"
          src={article.previewImage}
          alt={`${article.title}ButtonIcon`}
        />
        <div className="flex flex-col ml-2">
          <p className="text-sm font-bold">
            {handleCropContent(article.title, 36)}
          </p>
          <p className="text-sm">
            {handleCropContent(stripHtmlTags(article.content), 36)}
          </p>
        </div>
      </div>
      <div className="flex flex-row items-center w-full">
        <h3 className="w-full text-center">
          {article.createdAt.toLocaleDateString("de-DE")}
        </h3>
        <h3 className="w-full text-center">
          {article.views ? article.views : "TBA"}
        </h3>
        <h3 className="w-full text-center">
          {article.likes ? article.likes : "TBA"}
        </h3>
        <h3 className="w-full text-center">
          {/* {article.comments ? article.comments.length : "TBA"} */}
          <AdminCheckbox checked={isPulished} onChange={toogleIsPublished} />
        </h3>
        <div className="w-full flex items-center justify-center">
          <AdminPanelButton
            icon={PencilBlueIcon}
            title="Edit"
            onClick={() => {
              setCurrentArticle(article);
              redirectTo(`/admin/edit-article/${article._id}`);
            }}
            additionalTitleStyles="!text-black"
          />
        </div>
      </div>
    </div>
  );
};

export default AdminArticleCard;

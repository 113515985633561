import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import ArrowIcon from "../../assets/common/arrow_right.svg";

import RectangularButton from "../inputs/rectangularButton";
import CustomCheckbox from "../inputs/checkbox";

const PrivacyPolicy = ({ onNext, onBack }: any) => {
  const { t } = useTranslation();

  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked);
  };

  return (
    <div className="flex flex-col items-center px-8">
      <button
        className="absolute top-6 left-6 text-gray-500 hover:text-gray-700 rotate-180"
        onClick={onBack}
      >
        <img width={12} height={10} src={ArrowIcon} alt="BackIcon" />
      </button>
      <h1 className="text-2xl font-bold my-4 ml-6 w-full">
        {t("Privacy")} <span className="text-olive">{t("policy")}</span>
      </h1>
      <div className="p-4 shadow-sm rounded-xl max-h-80 overflow-y-scroll">
        <p className="font-bold text-sm">
          Privacy Policy for VivoOlio Effective Date: Jule 31, 2024 This Privacy
          Policy explains how VivoOlio ApS collects, uses, and shares your
          personal information when you use our Services. If you have any
          questions or comments about this Privacy Policy, please contact us at
          privacy@vivoolio.com. About Us VivoOlio ApS is an international online
          platform that provides a space for registered users to purchase, rate,
          and review olive oil products and share this information with the
          community. This policy covers all interactions on our website, (url
          site ) and our mobile application, collectively referred to as the
          "Platform". Changes to This Policy We may update this Privacy Policy
          occasionally. You will receive adequate notice before any new policies
          take effect. Data Controller VivoOlio ApS, расположение , is the data
          controller responsible for processing personal data. We comply with
          the European General Data Protection Regulation (GDPR). Data
          Collection and Use We collect personal data to enhance your
          experience, manage our user relationship, support and improve the
          platform, and fulfill our contractual obligations. The types of data
          we collect include: Registration Data: Email, password, username,
          name, country of origin, and preferred language. If you register, we
          collect the information authorized by you. Interaction Data:
          Information related to your activities on the Platform, such as
          ratings, purchases, and interactions with other users. Technical Data:
          Data about how you access our services, which can include IP
          addresses, browser type, and device information.
        </p>
      </div>

      <div className="w-full flex flex-col items-center justify-center mt-8">
        <CustomCheckbox
          checked={isChecked}
          onChange={handleCheckboxChange}
          label={t("I agree with the privacy policy.")}
        />
      </div>
      <div className="w-1/3 flex flex-col items-center justify-center my-6">
        <RectangularButton
          title="Next"
          onClick={onNext}
          isDisabled={!isChecked}
        />
      </div>
    </div>
  );
};

export default PrivacyPolicy;

import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../context/GlobalContext";

import LargeArticleCard from "../components/cards/largeArticle";
import MeduimArticleCard from "../components/cards/meduimArticle";

// Test Data
const TEST_ARTICLES = [
  {
    _id: "123123",
    title: "VivoOlio: The best recipes for dishes with olives.",
    createdAt: new Date(),
    author: {
      name: "VivoOlio",
      image:
        "https://beautyrepublicfdl.com/wp-content/uploads/2020/06/placeholder-image.jpg",
    },
    previewImage:
      "https://beautyrepublicfdl.com/wp-content/uploads/2020/06/placeholder-image.jpg",
    content: "text_1",
  },
  {
    _id: "123124",
    title: "VivoOlio: The Ancient Roots of Olive Oil Production.",
    createdAt: new Date(2024, 2, 12, 10, 0),
    author: {
      name: "VivoOlio",
      image:
        "https://beautyrepublicfdl.com/wp-content/uploads/2020/06/placeholder-image.jpg",
    },
    previewImage:
      "https://beautyrepublicfdl.com/wp-content/uploads/2020/06/placeholder-image.jpg",
    content: "text_2",
  },
  {
    _id: "123121",
    title: "VivoOlio: The Olive Oil Industry's Response to Climate Change.",
    createdAt: new Date(2021, 8, 12, 10, 0),
    author: {
      name: "VivoOlio",
      image:
        "https://beautyrepublicfdl.com/wp-content/uploads/2020/06/placeholder-image.jpg",
    },
    previewImage:
      "https://beautyrepublicfdl.com/wp-content/uploads/2020/06/placeholder-image.jpg",
    content: "text_3",
  },
];

const MainPage = () => {
  const { t } = useTranslation();
  const globalContext = useContext(GlobalContext);

  const { recentNews, newsFeed, user } = globalContext;

  useEffect(() => {
    console.log("Changed user: ", user);
  }, [user]);

  return (
    <div className="w-full pb-8 px-section">
      <div className="flex flex-row w-full items-center justify-between my-6 mb-8">
        <p className="font-bold text-2xl">
          {t("Today in the ")} <span className="text-blue">{t("news")}</span>
        </p>
      </div>

      <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-x-0 md:gap-x-6">
        <div className="flex flex-col w-full h-full">
          <LargeArticleCard article={recentNews[0]} />
        </div>
        <div className="w-full grid grid-cols-1 gap-y-3 mt-6 md:mt-0">
          {recentNews.slice(1).map((article: any) => (
            <MeduimArticleCard article={article} />
          ))}
        </div>
      </div>

      <div className="w-full h-1 border-b border-zinc-500 my-12" />
      <div className="flex flex-row w-full items-center justify-between my-6 mb-8">
        <p className="font-bold text-2xl">
          {t("News ")} <span className="text-blue">{t("feed")}</span>
        </p>
      </div>

      <div className="w-full grid md:grid-cols-2 grid-cols-1 gap-x-0 md:gap-x-6 gap-y-6 mb-20">
        {newsFeed.map((article: any) => (
          <MeduimArticleCard article={article} />
        ))}
      </div>
    </div>
  );
};

export default MainPage;

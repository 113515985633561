import React from "react";
import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

const DownloadFromButton = ({ icon, onClick }: any) => {
  const { t } = useTranslation();
  const location = useLocation();

  const isAdminPath = location.pathname.startsWith("/admin");
  return (
    <button
      onClick={onClick}
      className="flex flex-col justify-center items-center px-8 py-2 rounded-full"
      style={{ backgroundColor: "#0096F7" }}
    >
      <span className="ml-2 text-white font-light" style={{ fontSize: "10px" }}>
        {t("Download from")}
      </span>
      {icon && <img src={icon} alt={`DownloadFromIcon`} className="" />}
    </button>
  );
};

export default DownloadFromButton;

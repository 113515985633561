// src/i18n.ts
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./locales/en.json";
import translationAR from "./locales/ar.json";
import translationCH from "./locales/ch.json";
import translationDE from "./locales/de.json";
import translationFR from "./locales/fr.json";
import translationHI from "./locales/hi.json";
import translationJP from "./locales/jp.json";
import translationRU from "./locales/ru.json";
import translationSP from "./locales/sp.json";

const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
  ch: {
    translation: translationCH,
  },
  de: {
    translation: translationDE,
  },
  fr: {
    translation: translationFR,
  },
  hi: {
    translation: translationHI,
  },
  jp: {
    translation: translationJP,
  },
  ru: {
    translation: translationRU,
  },
  sp: {
    translation: translationSP,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

import React from "react";

const IconButton = ({
  icon,
  onClick,
  width,
  height,
  additionalStyles = "",
}: any) => {
  return (
    <button onClick={onClick}>
      {icon && (
        <img
          src={icon}
          alt={`IconButton`}
          width={width ? width : 25}
          height={height ? height : 25}
          className={`${additionalStyles}`}
        />
      )}
    </button>
  );
};

export default IconButton;

import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

type PopupButton = {
  title: string;
  onClick: () => void;
  icon?: JSX.Element | string;
};

interface PopupProps {
  children: React.ReactNode;
  buttons: PopupButton[];
  orientation?: string;
}

const Popup: React.FC<PopupProps> = ({
  children,
  buttons,
  orientation = "bottom-left",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative" ref={popupRef}>
      <div onClick={() => setIsOpen(!isOpen)} className="cursor-pointer">
        {children}
      </div>

      {isOpen && (
        <div
          className={`absolute ${
            orientation === "bottom-right" ? "left-0" : "right-0"
          } mt-2 bg-white shadow-lg border rounded-lg w-48 z-50`}
        >
          {buttons.map((button, index) => (
            <button
              key={index}
              onClick={() => {
                button.onClick();
                setIsOpen(false);
              }}
              className="flex items-center w-full px-4 py-2 hover:bg-gray-100"
            >
              {button.icon && (
                <>
                  {typeof button.icon === "string" ? (
                    <img
                      src={button.icon}
                      alt={button.title}
                      className="w-4 h-4 mr-2"
                    />
                  ) : (
                    // <span className="mr-2 rounded-full overflow-hidden w-4 h-4 bg-red-100 flex items-center justify-center">
                    button.icon
                    // </span>
                  )}
                </>
              )}
              {t(button.title)}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Popup;

import React from "react";
import { useTranslation } from "react-i18next";

const RectangularButton = ({
  icon,
  onClick,
  title,
  additionalStyles = "",
  isDisabled = false,
}: any) => {
  const { t } = useTranslation();

  return (
    <button
      disabled={isDisabled}
      className={`w-full flex flex-row items-center justify-center py-2 bg-olive rounded-lg ${additionalStyles} ${
        isDisabled && "bg-zinc-600"
      }`}
      style={isDisabled ? { backgroundColor: "#848484" } : {}}
      onClick={onClick}
    >
      {icon && (
        <img
          src={icon}
          alt={`RectangularButton`}
          width={25}
          height={25}
          className=""
        />
      )}
      <span className="text-white font-bold text-sm">{t(title)}</span>
    </button>
  );
};

export default RectangularButton;

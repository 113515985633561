import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../context/GlobalContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

import AuthEmail from "./AuthEmail";
import AuthPassword from "./AuthPassword";
import SignUpEmail from "./SignUpEmail";
import SignUpPassword from "./SignUpPassword";
import PrivacyPolicy from "./PrivacyPolicy";

let prev_authState = "auth_email";

const AuthModal = ({ isOpen, onClose }: any) => {
  const { t } = useTranslation();
  const globalContext = useContext(GlobalContext);

  const {
    setUser,
    PUBLIC_API_URL,
    GOOGLE_CLIENT_ID,
    APPLE_CLIENT_ID,
    setIsAuthenticated,
  } = globalContext;

  const [authState, setAuthState] = useState("auth_email");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCofirm, setPasswordConfirm] = useState("");

  const handleNext = (newState: string) => {
    prev_authState = authState;
    setAuthState(newState);
  };

  const loginEmail = async () => {
    try {
      console.log(email, password);
      const response = await fetch(`${PUBLIC_API_URL}/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      const data = await response.json();
      console.log(data);

      if (response.status === 404) {
        return null;
      }
      if (response.status > 399) {
        return null;
      }

      console.log("setting user to: ", data.user);
      setUser(data.user);
      localStorage.setItem("token", data.accessToken);
      onClose();
      setIsAuthenticated(true);
      return data;
    } catch (error) {
      console.error("Failed to login", error);
      throw error;
    }
  };

  const loginGoogle = async ({
    googleId,
    email,
    family_name,
    given_name,
  }: any) => {
    try {
      console.log(googleId, email, family_name, given_name);
      const response = await fetch(`${PUBLIC_API_URL}/auth/google`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          googleId,
          email,
          name: given_name,
          surname: family_name,
        }),
      });

      const data = await response.json();
      console.log(data);

      if (response.status > 399) return;
      setUser(data.user);
      localStorage.setItem("token", data.accessToken);
      onClose();
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Failed to login", error);
      throw error;
    }
  };

  const loginApple = async ({
    appleId,
    email,
    family_name,
    given_name,
  }: any) => {
    try {
      console.log(appleId, email, family_name, given_name);
      const response = await fetch(`${PUBLIC_API_URL}/auth/apple`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          appleId,
          email,
          name: given_name,
          surname: family_name,
        }),
      });

      const data = await response.json();
      console.log(data);

      if (response.status > 399) return;
      setUser(data.user);
      localStorage.setItem("token", data.accessToken);
      onClose();
      setIsAuthenticated(true);
      return data;
    } catch (error) {
      console.error("Failed to login", error);
      throw error;
    }
  };

  const registerByEmail = async () => {
    try {
      const response = await fetch(`${PUBLIC_API_URL}/auth/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
          password: password,
          isProfessionalAccount: false,
        }),
      });
      const data = await response.json();
      setUser(data.user);
      localStorage.setItem("token", data.accessToken);
      console.log(data.user);
      onClose();
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Failed to login", error);
      throw error;
    }
  };

  const handleFinishAuth = async () => {
    if (prev_authState === "auth_password") {
      await loginEmail();
    } else if (prev_authState === "sign-up_password") {
      await registerByEmail();
    }
  };

  const handleGoogle = async ({ tokenResponse, userInfo }: any) => {
    console.log("Received Token:", tokenResponse.access_token);
    console.log("Received User Info:", userInfo);

    await loginGoogle({
      given_name: userInfo.given_name,
      family_name: userInfo.family_name,
      email: userInfo.email,
      googleId: userInfo.sub,
    });
  };

  const handleApple = async () => {
    try {
      window.AppleID.auth.init({
        clientId: APPLE_CLIENT_ID,
        scope: "name email",
        redirectURI: "example.com", // TODO: figure out why do i need redirect with usePopup 💀💀💀
        usePopup: true,
      });

      const response = await window.AppleID.auth.signIn();

      const { authorization, user } = response;

      await loginApple({
        appleId: authorization.id_token,
        email: user?.email || "",
        family_name: user?.name?.lastName || "",
        given_name: user?.name?.firstName || "",
      });
    } catch (error) {
      console.error("Apple login failed", error);
    }
  };

  if (!isOpen) return null;
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <div
        className="fixed inset-0 bg-zinc-700 bg-opacity-75 flex items-center justify-center z-50"
        onClick={onClose}
      >
        <div
          className={`${
            authState === "privacy-policy" ? "md:w-1/2" : "md:w-1/3"
          } bg-white rounded-lg shadow-lg p-6 relative w-5/6`}
          onClick={(e) => e.stopPropagation()}
        >
          {authState === "auth_email" && (
            <AuthEmail
              email={email}
              setEmail={setEmail}
              onClose={onClose}
              onNext={() => handleNext("auth_password")}
              onSignUp={() => handleNext("sign-up_email")}
              handleGoogle={handleGoogle}
              handleApple={handleApple}
            />
          )}
          {authState === "auth_password" && (
            <AuthPassword
              password={password}
              setPassword={setPassword}
              onClose={onClose}
              onNext={() => handleNext("privacy-policy")}
              onBack={() => handleNext("auth_email")}
              onSignUp={() => handleNext("sign-up_email")}
            />
          )}
          {authState === "sign-up_email" && (
            <SignUpEmail
              email={email}
              setEmail={setEmail}
              onClose={onClose}
              onNext={() => handleNext("sign-up_password")}
              onSignIn={() => handleNext("auth_email")}
              handleGoogle={handleGoogle}
              handleApple={handleApple}
            />
          )}
          {authState === "sign-up_password" && (
            <SignUpPassword
              password={password}
              setPassword={setPassword}
              confirmPassword={passwordCofirm}
              setConfirmPassword={setPasswordConfirm}
              onClose={onClose}
              onBack={() => handleNext("sign-up_email")}
              onNext={() => handleNext("privacy-policy")}
              onSignIn={() => handleNext("auth_email")}
            />
          )}
          {authState === "privacy-policy" && (
            <PrivacyPolicy
              onBack={() => handleNext(prev_authState)}
              onNext={() => handleFinishAuth()}
            />
          )}
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default AuthModal;

import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { useTranslation } from "react-i18next";
import useRedirect from "../../hooks/useRedirect";
import { useLocation } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";

import ProfileIcon from "../../assets/Profile.svg";
import ProfileBlueIcon from "../../assets/common/user_blue.svg";
import PlanetIcon from "../../assets/Planet.svg";
import PlanetBlueIcon from "../../assets/common/planet-blue.svg";
import UserPicture from "../../assets/profile/user_picture.svg";

import Popup from "../inputs/popup";
import IconButton from "../inputs/iconButton";
import AuthModal from "../auth/AuthModal";

function Header() {
  const globalContext = useContext(GlobalContext);
  const redirectTo = useRedirect();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [currentLang, setCurrentLang] = useState<string>("EN");
  const [isAuthOpen, setIsAuthOpen] = useState<boolean>(false);

  if (!globalContext) {
    throw new Error("GlobalContext must be used within a GlobalProvider");
  }

  const { isAuthenticated, user } = globalContext;

  const DISPLAYED_LANGUAGE_OPTIONS = [
    {
      title: "EN",
      code: "en",
      onClick: () => {
        setCurrentLang("EN");
        i18n.changeLanguage("en");
      },
      icon: (
        <ReactCountryFlag
          countryCode="GB"
          svg
          style={{
            height: "20px",
            marginRight: "5px",
          }}
        />
      ),
    },
    {
      title: "AR",
      code: "ar",
      onClick: () => {
        setCurrentLang("AR");
        i18n.changeLanguage("ar");
      },
      icon: (
        <ReactCountryFlag
          countryCode="SA"
          svg
          style={{
            height: "20px",
            marginRight: "5px",
          }}
        />
      ),
    },
    {
      title: "CH",
      code: "ch",
      onClick: () => {
        setCurrentLang("CH");
        i18n.changeLanguage("ch");
      },
      icon: (
        <ReactCountryFlag
          countryCode="CN"
          svg
          style={{
            height: "20px",
            marginRight: "5px",
          }}
        />
      ),
    },
    {
      title: "DE",
      code: "de",
      onClick: () => {
        setCurrentLang("DE");
        i18n.changeLanguage("de");
      },
      icon: (
        <ReactCountryFlag
          countryCode="DE"
          svg
          style={{
            height: "20px",
            marginRight: "5px",
          }}
        />
      ),
    },
    {
      title: "FR",
      code: "fr",
      onClick: () => {
        setCurrentLang("FR");
        i18n.changeLanguage("fr");
      },
      icon: (
        <ReactCountryFlag
          countryCode="FR"
          svg
          style={{
            height: "20px",
            marginRight: "5px",
          }}
        />
      ),
    },
    {
      title: "HI",
      code: "hi",
      onClick: () => {
        setCurrentLang("HI");
        i18n.changeLanguage("hi");
      },
      icon: (
        <ReactCountryFlag
          countryCode="IN"
          svg
          style={{
            height: "20px",
            marginRight: "5px",
          }}
        />
      ),
    },
    {
      title: "JP",
      code: "jp",
      onClick: () => {
        setCurrentLang("JP");
        i18n.changeLanguage("jp");
      },
      icon: (
        <ReactCountryFlag
          countryCode="JP"
          svg
          style={{
            height: "20px",
            marginRight: "5px",
          }}
        />
      ),
    },
    {
      title: "RU",
      code: "ru",
      onClick: () => {
        setCurrentLang("RU");
        i18n.changeLanguage("ru");
      },
      icon: (
        <ReactCountryFlag
          countryCode="RU"
          svg
          style={{
            height: "20px",
            marginRight: "5px",
          }}
        />
      ),
    },
    {
      title: "SP",
      code: "sp",
      onClick: () => {
        setCurrentLang("SP");
        i18n.changeLanguage("sp");
      },
      icon: (
        <ReactCountryFlag
          countryCode="ES"
          svg
          style={{
            height: "20px",
            marginRight: "5px",
          }}
        />
      ),
    },
  ];

  const isAdminPath = location.pathname.startsWith("/admin");
  return (
    <div className={"border-b-blue"}>
      <div className="h-12 flex flex-row items-center justify-between px-section">
        <div className="flex flex-row h-full items-center">
          <Popup
            buttons={DISPLAYED_LANGUAGE_OPTIONS}
            orientation="bottom-right"
          >
            <IconButton
              additionalStyles="mt-1"
              icon={PlanetBlueIcon}
              onClick={() => console.log("icon-button clicked")}
            />
          </Popup>

          {/* <div className="ml-2" />
          {DISPLAYED_LANGUAGE_OPTIONS.map((lang, index) => (
            <div
              key={index}
              className="p-1 cursor-pointer"
              style={
                currentLang === lang.title
                  ? {
                      borderBottom: "2px solid #0096f7",
                    }
                  : {}
              }
              onClick={() => {
                setCurrentLang(lang.title);
                i18n.changeLanguage(lang.code);
              }}
            >
              <span
                className={
                  currentLang === lang.title
                    ? `font-bold text-blue`
                    : "font-bold"
                }
              >
                {lang.title}
              </span>
            </div>
          ))} */}
        </div>
        <h1
          className="text-2xl font-bold cursor-pointer"
          onClick={() => redirectTo("/")}
        >
          vivoolio.
          <span className="text-blue">today</span>
        </h1>
        <div className="flex flex-row h-full items-center">
          <div className="mx-3" />
          {isAuthenticated ? (
            <>
              <IconButton
                icon={ProfileBlueIcon}
                // onClick={() => redirectTo("/profile")}
              />{" "}
              <span
                className="font-bold ml-1 cursor-pointer"
                // onClick={() => redirectTo("/profile")}
              >
                {user?.name}
              </span>
            </>
          ) : (
            <IconButton
              icon={ProfileBlueIcon}
              onClick={() => setIsAuthOpen(true)}
            />
          )}
        </div>
      </div>

      <AuthModal isOpen={isAuthOpen} onClose={() => setIsAuthOpen(false)} />
    </div>
  );
}

export default Header;

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import useRedirect from "../../hooks/useRedirect";
import { GlobalContext } from "../../context/GlobalContext";

import ProfilePicture from "../../assets/Profile.svg";
import ProfileBluePicture from "../../assets/common/user_blue.svg";

import RelativeTime from "../common/relativeTime";

const LargeArticleCard = ({ article, isSecondary = false }: any) => {
  const { t } = useTranslation();
  const redirectTo = useRedirect();
  const globalContext = useContext(GlobalContext);

  const { setCurrentArticle } = globalContext;

  if (article === undefined) return null;
  return (
    <button
      className="w-full text-start"
      onClick={() => {
        setCurrentArticle(article);
        redirectTo(`/article/${article._id}`);
      }}
    >
      <img
        className="w-full rounded-lg shadow-sm"
        src={article.previewImage}
        alt={`${article.title}Image`}
      />
      <h1
        className={`${
          isSecondary ? "text-lg my-1" : "text-2xl my-4"
        } font-bold`}
      >
        {article.title}
      </h1>
      <div
        className={`${
          isSecondary ? "mt-1" : "mt-3"
        } flex flex-row items-center justify-between`}
      >
        <div
          className="flex flex-row items-center text-zinc-500"
          style={isSecondary ? { fontSize: "10px" } : { fontSize: "15px" }}
        >
          <img
            className={`${
              isSecondary ? "h-6 w-6 p-1" : "h-10 w-10 p-2"
            } rounded-full bg-zinc-200`}
            src={ProfileBluePicture}
            alt="AuthorPicture"
          />
          <span className="mx-2 text-black">{article.author}</span>
          <RelativeTime date={article.createdAt} />
        </div>
        <span className="text-sm">
          {article.createdAt.toLocaleDateString("de-DE")}
        </span>
      </div>
    </button>
  );
};

export default LargeArticleCard;

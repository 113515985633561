import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import AppStoreIcon from "../../assets/dowload_platforms/appStore.svg";
import GooglePlayIcon from "../../assets/dowload_platforms/googlePlay.svg";
import GooglePlayBlueIcon from "../../assets/dowload_platforms/google_play_blue.svg";

import DownloadFromButton from "../inputs/downloadFromButton";

function Footer() {
  const globalContext = useContext(GlobalContext);
  const { t } = useTranslation();
  const location = useLocation();

  if (!globalContext) {
    throw new Error("GlobalContext must be used within a GlobalProvider");
  }

  const { state } = globalContext;

  const isAdminPath = location.pathname.startsWith("/admin");
  return (
    <div
      className="bg-black flex flex-col items-center justify-center py-10 bg-footer"
      style={{ fontSize: "15px" }}
    >
      <div className="flex flex-col md:flex-row w-full items-center justify-center lg:w-1/2">
        <div className="grid grid-cols-2 gap-4 w-2/3 font-light text-white">
          <h1 className="text-2xl font-bold text-white text-center">
            Vivo
            <span className={"text-blue"}>Olio</span>
          </h1>
          <button className="p-2">{t("Terms of sale")}</button>
          <button className="p-2">{t("About us")}</button>
          <button className="p-2">{t("Privacy policy")}</button>
          <button className="p-2">{t("Contacts")}</button>
          <button className="p-2">{t("Term of use")}</button>
          <button className="p-2">{t("News blog")}</button>
          <button className="p-2">{t("Current policy")}</button>
        </div>
        <div className="flex flex-col ml-6 w-1/3 items-center justify-around mt-8 md:mt-0">
          <DownloadFromButton
            icon={GooglePlayBlueIcon}
            onClick={() => console.log("redirect to google play")}
          />
          <div className="mt-4" />
          <DownloadFromButton
            icon={AppStoreIcon}
            onClick={() => console.log("redirect to app store")}
          />
        </div>
      </div>
      <div className="text-white text-center font-light mt-16">
        <p>©VivoOlio 2024</p>
        <p>{t("All rights reserved")}</p>
      </div>
    </div>
  );
}

export default Footer;

import React from "react";
import { useTranslation } from "react-i18next";

const AdminPanelButton = ({
  icon,
  title,
  additionalTitleStyles,
  isDisabled = false,
  onClick,
  width,
  height,
}: any) => {
  const { t } = useTranslation();

  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
      className={`${isDisabled && "opacity-7"} flex flex-row items-center`}
    >
      {icon && (
        <img
          src={icon}
          alt={`IconButton`}
          width={width ? width : 20}
          height={height ? height : 20}
          className="text-blue mr-1"
        />
      )}
      {title && (
        <span
          className={
            `text-md mr-3 text-blue ${isDisabled && "!text-zinc-400"} ` +
            additionalTitleStyles
          }
        >
          {t(title)}
        </span>
      )}
    </button>
  );
};

export default AdminPanelButton;

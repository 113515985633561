import React from "react";
import { useTranslation } from "react-i18next";

interface RelativeTimeProps {
  date: Date | string;
}

const RelativeTime: React.FC<RelativeTimeProps> = ({ date }) => {
  const { t } = useTranslation();

  const dateObj = typeof date === "string" ? new Date(date) : date;
  const now = new Date();

  const seconds = Math.floor((now.getTime() - dateObj.getTime()) / 1000);

  const interval = Math.floor(seconds / 31536000);
  if (interval > 1)
    return (
      <span className="">
        {interval} {t("years ago")}
      </span>
    );

  const months = Math.floor(seconds / 2592000);
  if (months > 1)
    return (
      <span className="">
        {months} {t("months ago")}
      </span>
    );

  const weeks = Math.floor(seconds / 604800);
  if (weeks > 1)
    return (
      <span className="">
        {weeks} {t("weeks ago")}
      </span>
    );

  const days = Math.floor(seconds / 86400);
  if (days > 1)
    return (
      <span className="">
        {days} {t("days ago")}
      </span>
    );

  const hours = Math.floor(seconds / 3600);
  if (hours > 1)
    return (
      <span className="">
        {hours} {t("hours ago")}
      </span>
    );

  const minutes = Math.floor(seconds / 60);
  if (minutes > 1)
    return (
      <span className="">
        {minutes} {t("minutes ago")}
      </span>
    );

  const secs = Math.floor(seconds);
  return (
    <span className="">
      {secs} {t("seconds ago")}
    </span>
  );
};

export default RelativeTime;

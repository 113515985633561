import React, { useContext, useEffect, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../context/GlobalContext";
import ReactCountryFlag from "react-country-flag";

import ProfileBluePicture from "../assets/common/user_blue.svg";
import PlanetBlueIcon from "../assets/common/planet-blue.svg";

import Popup from "../components/inputs/popup";
import IconButton from "../components/inputs/iconButton";
import RenderRichText from "../components/common/richTextRenderer";
import RelativeTime from "../components/common/relativeTime";
import LargeArticleCard from "../components/cards/largeArticle";

const ArticlePage: React.FC = () => {
  const { t } = useTranslation();

  const globalContext = useContext(GlobalContext);
  const { id } = useParams<{ id: string }>();

  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang');

  const containerRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  const { currentArticle, setCurrentArticle, otherNewsData, PUBLIC_API_URL } =
    globalContext;

  const fetchArticle = async (lang: null | string = null) => {
    try {
      console.log("LANG", lang);
      console.log("FETCHING ARTICLE");
      const userLocale = navigator.language || navigator.languages[0];
      console.log(userLocale);
      const response = await fetch(
        `${PUBLIC_API_URL}/news/id/${id}?lang=${
          lang ? lang : userLocale.split("-")[0]
        }`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch news data");
      }

      let newsData = await response.json();

      let news = newsData.news;
      news.title = newsData.translated.translatedTitle;
      news.content = newsData.translated.translatedContent;
      console.log(newsData);
      console.log(newsData.news);
      console.log(news);

      // newsData.createdAt = new Date(newsData.news.createdAt);
      console.log("NEW ARTICLE DATA: ", news);
      setCurrentArticle(news);
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  if (currentArticle === undefined || currentArticle._id !== id) {
    console.log(lang);
    fetchArticle(lang);
  }

  useEffect(() => {
    if (containerRef.current) {
      // Ищем div с нужным текстом после рендеринга HTML
      const originDiv = (containerRef.current as any).querySelector(
        "div.mt-2.w-full.flex.justify-end"
      );
      console.log(originDiv);
      if (originDiv && targetRef.current) {
        while (targetRef.current.firstChild) {
          targetRef.current.removeChild(targetRef.current.firstChild);
        }

        const clonedElement = originDiv.cloneNode(true);
        (targetRef.current as any).appendChild(clonedElement);
      }
    }
  }, [currentArticle?.content]);

  const DISPLAYED_LANGUAGE_OPTIONS = [
    {
      title: "EN",
      code: "en",
      onClick: () => {
        fetchArticle("en");
      },
      icon: (
        <ReactCountryFlag
          countryCode="GB"
          svg
          style={{
            height: "20px",
            marginRight: "5px",
          }}
        />
      ),
    },
    {
      title: "AR",
      code: "ar",
      onClick: () => {
        fetchArticle("ar");
      },
      icon: (
        <ReactCountryFlag
          countryCode="SA"
          svg
          style={{
            height: "20px",
            marginRight: "5px",
          }}
        />
      ),
    },
    {
      title: "CH",
      code: "ch",
      onClick: () => {
        fetchArticle("ch");
      },
      icon: (
        <ReactCountryFlag
          countryCode="CN"
          svg
          style={{
            height: "20px",
            marginRight: "5px",
          }}
        />
      ),
    },
    {
      title: "DE",
      code: "de",
      onClick: () => {
        fetchArticle("de");
      },
      icon: (
        <ReactCountryFlag
          countryCode="DE"
          svg
          style={{
            height: "20px",
            marginRight: "5px",
          }}
        />
      ),
    },
    {
      title: "FR",
      code: "fr",
      onClick: () => {
        fetchArticle("fr");
      },
      icon: (
        <ReactCountryFlag
          countryCode="FR"
          svg
          style={{
            height: "20px",
            marginRight: "5px",
          }}
        />
      ),
    },
    {
      title: "HI",
      code: "hi",
      onClick: () => {
        fetchArticle("hi");
      },
      icon: (
        <ReactCountryFlag
          countryCode="IN"
          svg
          style={{
            height: "20px",
            marginRight: "5px",
          }}
        />
      ),
    },
    {
      title: "JP",
      code: "jp",
      onClick: () => {
        fetchArticle("jp");
      },
      icon: (
        <ReactCountryFlag
          countryCode="JP"
          svg
          style={{
            height: "20px",
            marginRight: "5px",
          }}
        />
      ),
    },
    {
      title: "RU",
      code: "ru",
      onClick: () => {
        fetchArticle("ru");
      },
      icon: (
        <ReactCountryFlag
          countryCode="RU"
          svg
          style={{
            height: "20px",
            marginRight: "5px",
          }}
        />
      ),
    },
    {
      title: "SP",
      code: "sp",
      onClick: () => {
        fetchArticle("sp");
      },
      icon: (
        <ReactCountryFlag
          countryCode="ES"
          svg
          style={{
            height: "20px",
            marginRight: "5px",
          }}
        />
      ),
    },
  ];

  return (
    <>
      {currentArticle === undefined ? (
        <div />
      ) : (
        <div className="w-full flex flex-col md:flex-row mt-4 px-section pt-12">
          <div className="flex flex-col w-full md:w-2/3">
            <h1 className="font-bold text-2xl">{currentArticle.title}</h1>
            <div className="flex flex-row items-center justify-between my-6">
              <div
                className="flex flex-row items-center text-zinc-500"
                style={{ fontSize: "15px" }}
              >
                <img
                  className="rounded-full h-10 w-10 bg-zinc-200 p-2"
                  src={ProfileBluePicture}
                  alt="AuthorPicture"
                />
                <span className="mx-2 text-black">{currentArticle.author}</span>
                <RelativeTime date={currentArticle.createdAt} />
                <span className="text-sm ml-2" style={{ fontSize: "15px" }}>
                  {new Date(currentArticle.createdAt).toLocaleDateString(
                    "de-DE"
                  )}
                </span>
                <Popup
                  buttons={DISPLAYED_LANGUAGE_OPTIONS}
                  orientation="bottom-right"
                >
                  <IconButton
                    additionalStyles="ml-4"
                    icon={PlanetBlueIcon}
                    onClick={() => console.log("icon-button clicked")}
                  />
                </Popup>
              </div>
            </div>
            <img
              className="rounded-lg shadow-sm w-full "
              src={currentArticle.previewImage}
              alt={`${currentArticle.title}Image`}
            />
            {/* <p className="text-sm mt-10 mb-16">{currentArticle.content}</p> */}
            <div className="text-sm mt-10 mb-16">
              <div ref={targetRef} style={{ color: "#0096f7" }}></div>
              <RenderRichText
                richText={currentArticle.content}
                htmlRef={containerRef}
              />
            </div>
          </div>
          <div className="flex flex-col w-full md:w-1/3 ml-0 md:ml-8">
            <p className="font-bold text-lg my-4">
              {t("Other ")} <span className="text-blue">{t("news")}</span>
            </p>
            {otherNewsData.map((article: any) => (
              <>
                <LargeArticleCard
                  article={article}
                  isSecondary={true}
                  key={article._id}
                />
                <div className="mt-4" key={article._id + "-margin"} />
              </>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ArticlePage;

import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const useRedirect = () => {
  const navigate = useNavigate();

  const redirectTo = (path: string) => {
    navigate(path);
  };

  return redirectTo;
};

export default useRedirect;

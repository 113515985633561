import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../context/GlobalContext";
import { Routes, Route } from "react-router-dom";
import useRedirect from "../../hooks/useRedirect";

import AdminMain from "./main";
import PublishArticlePage from "./publish";
import EditArticlePage from "./edit";

const AdminPage = () => {
  const { t } = useTranslation();
  const globalContext = useContext(GlobalContext);
  const redirectTo = useRedirect();
  const { user, PUBLIC_API_URL, setUser, setIsAuthenticated } = globalContext;

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");

      if (token) {
        try {
          const response = await fetch(`${PUBLIC_API_URL}/users/token`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            throw new Error("Failed to fetch user data");
          }

          const data = await response.json();
          console.log("Fetched user data:", data);

          if (data.role !== "admin") {
            console.log("user in admin", user);
            redirectTo("/");
          }

          setUser(data);
          setIsAuthenticated(true);
        } catch (err) {
          console.error("Error fetching user data:", err);
        }
      } else {
        redirectTo("/");
      }
    };

    fetchUserData();
  }, []);

  return (
    <div className="">
      <Routes>
        <Route index element={<AdminMain />} />
        <Route path="edit-article/:id" element={<EditArticlePage />} />
        <Route path="create-article" element={<PublishArticlePage />} />

        <Route path="*" element={<div>404: Admin Route not Found</div>} />
      </Routes>
    </div>
  );
};

export default AdminPage;

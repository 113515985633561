import React from "react";
import { useTranslation } from "react-i18next";

const SettingsInput = ({
  value,
  setValue,
  label,
  placeholder,
  isTextArea = false,
  additionalStyles = "",
  isCensored = false,
}: any) => {
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <p className="text-sm font-light mb-1">{t(label)}</p>
      {isTextArea ? (
        <textarea
          value={value}
          placeholder={placeholder ? t(placeholder) : ""}
          onChange={(e) => setValue(e.target.value)}
          className={`font-light w-full flex items-start justify-start px-2 py-1 rounded-md border border-gray-400 ${additionalStyles}`}
          style={{ fontSize: "13px" }}
        />
      ) : (
        <input
          type={isCensored ? "password" : "text"}
          value={value}
          placeholder={placeholder ? t(placeholder) : ""}
          onChange={(e) => setValue(e.target.value)}
          className={`font-light w-full flex items-start justify-start px-2 py-1 rounded-md border border-gray-400 ${additionalStyles}`}
          style={{ fontSize: "13px" }}
        />
      )}
    </div>
  );
};

export default SettingsInput;

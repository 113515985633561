import React from "react";
import { useTranslation } from "react-i18next";

import SearchIcon from "../../assets/InputSearch.svg";

const SearchInput = ({ value, setValue }: any) => {
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={t("Search...")}
        className="font-light w-full px-6 py-1 rounded-md border border-zinc-300"
        style={{ fontSize: "13px" }}
      />
      <div className="h-0 " style={{ overflow: "visible" }}>
        <img
          className="relative"
          style={{ top: -22, left: 5 }}
          src={SearchIcon}
          alt="SearchInputIcon"
          width={15}
          height={15}
        />
      </div>
    </div>
  );
};

export default SearchInput;

import React, { createContext, useState } from "react";

export const GlobalContext = createContext<any | undefined>(undefined);

const PUBLIC_API_URL = process.env.REACT_APP_PUBLIC_API_URL;
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;

export const GlobalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [otherNewsData, setOtherNewsData] = useState([]);
  const [recentNews, setRecentNews] = useState([]);
  const [newsFeed, setNewsFeed] = useState([]);
  const [currentArticle, setCurrentArticle] = useState();
  const [newsData, setNewsData] = useState([]);
  const [adminNewsData, setAdminNewsData] = useState([]);

  const [user, setUser] = useState<any>({
    googleId: "goodleid",
    appleId: "appleid",
    email: "test@gmail.com",
    name: "Oliver",
    surname: "Oliveson",
    country: "Hungary",
    language: "DE",
    password: "1234",
    nickname: "oliver_oli",
    description: "Lorem Ipsum dolor...",
    links: ["133123.com", "3123221.org"],
    subscription: "subscription type",
    role: "user",
    isProfessionalAccount: true,
    consultingsLeft: 4,
    refreshToken: "token_lies_here",
    confirmCode: "9876",
    acceptedPrivacyPolicy: false,
    createdAt: new Date(),
    following: 132,
    followers: 12,
  });

  const updateData = async () => {
    try {
      const response = await fetch(`${PUBLIC_API_URL}/news/published`);
      if (!response.ok) {
        throw new Error("Failed to fetch news data");
      }

      let newsData = await response.json();
      newsData = newsData.map((item: any) => ({
        ...item,
        createdAt: new Date(item.createdAt),
      }));

      const sortedNews = newsData.sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );

      setNewsData(sortedNews);
      //console.log("NEWs data :", sortedNews[0]);

      const recent = sortedNews.slice(0, 4);
      setRecentNews(recent);
      const feed = sortedNews.slice(4);
      setNewsFeed(feed);
      const otherNews = newsData.slice(-3);
      setOtherNewsData(otherNews);
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  const updateAdminData = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`${PUBLIC_API_URL}/news`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch news data");
      }

      let newsData = await response.json();
      newsData = newsData.map((item: any) => ({
        ...item,
        createdAt: new Date(item.createdAt),
      }));

      const sortedNews = newsData.sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );

      setAdminNewsData(sortedNews);
      //console.log("NEWs data :", sortedNews[0]);

      // const recent = sortedNews.slice(0, 4);
      // setRecentNews(recent);
      // const feed = sortedNews.slice(4);
      // setNewsFeed(feed);
      // const otherNews = newsData.slice(-3);
      // setOtherNewsData(otherNews);
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        PUBLIC_API_URL,
        GOOGLE_CLIENT_ID,
        APPLE_CLIENT_ID,
        isAuthenticated,
        setIsAuthenticated,
        user,
        setUser,
        otherNewsData,
        setOtherNewsData,
        recentNews,
        setRecentNews,
        newsFeed,
        setNewsFeed,
        currentArticle,
        setCurrentArticle,
        newsData,
        setNewsData,
        updateData,
        adminNewsData,
        setAdminNewsData,
        updateAdminData,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

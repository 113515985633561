import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import useRedirect from "../../hooks/useRedirect";
import ReactQuill from "react-quill";
import Quill from "quill";
import "react-quill/dist/quill.snow.css";

import SettingsInput from "../../components/inputs/settingsInput";
import RectangularButton from "../../components/inputs/rectangularButton";

import UploadImageIcon from "../../assets/admin/upload_image.svg";

// Test Data
const article_data = {
  _id: "123121",
  title: "VivoOlio: The Olive Oil Industry's Response to Climate Change.",
  createdAt: new Date(2021, 8, 12, 10, 0),
  author: {
    name: "VivoOlio",
    image:
      "https://beautyrepublicfdl.com/wp-content/uploads/2020/06/placeholder-image.jpg",
  },
  previewImage:
    "https://beautyrepublicfdl.com/wp-content/uploads/2020/06/placeholder-image.jpg",
  content:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer hendrerit nunc nec auctor elementum. Maecenas laoreet, nisl vitae molestie blandit, dolor enim tincidunt orci, sit amet lobortis mi erat eu nibh. Curabitur luctus pulvinar varius. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus et tincidunt ligula, eu congue sem. Nulla in maximus leo. Maecenas sed orci vitae lacus iaculis pulvinar. Donec congue gravida aliquet. Cras lorem mi, cursus id tempus a, euismod nec elit. Morbi pharetra in ligula id aliquam. Aliquam erat volutpat. Vestibulum eu est nibh. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Etiam lacinia libero vel nibh interdum, sed tincidunt nibh varius. Nullam vel malesuada mi, ultricies ultrices turpis. Sed tristique orci sed sapien aliquet, sit amet semper lacus consequat.Donec sodales neque vel dapibus dapibus. Aenean ac eros tellus. Donec congue nisl id efficitur tempor. Pellentesque ultrices leo ac leo volutpat tempus. In hac habitasse platea dictumst. Cras blandit nunc eget nulla congue, nec malesuada nunc tempor. Nam lacinia aliquam dapibus. Nulla pharetra enim orci, dapibus posuere lectus facilisis quis.Nulla suscipit nisi nec neque commodo, maximus vehicula odio cursus. Phasellus porta sem eros, a commodo est rhoncus ac. Etiam vulputate urna vitae dolor vestibulum, ut porta velit iaculis. Aliquam ut risus lacinia, consequat massa pharetra, malesuada tortor. Quisque a sem a ex malesuada accumsan. Ut elementum purus id dui egestas, sollicitudin tincidunt tellus dictum. Etiam sed nunc consequat, maximus lorem sit amet, accumsan eros. Nam et orci et orci consectetur molestie eu a arcu. Morbi sed magna quam. Donec eu pulvinar est, vitae aliquet tortor.",
};

var Size = Quill.import("attributors/style/size");
Size.whitelist = [
  "10px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "24px",
  "30px",
];
Quill.register(Size, true);

const EditArticlePage: React.FC = () => {
  const { t } = useTranslation();
  const globalContext = useContext(GlobalContext);
  const redirectTo = useRedirect();
  const { id } = useParams<{ id: string }>();

  const { PUBLIC_API_URL, currentArticle, setCurrentArticle, updateData } =
    globalContext;

  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handlePublish = async () => {
    const token = localStorage.getItem("token");
    const newsData = {
      title: articleTitle,
      content: articleContent,
      previewImage: articleImg,
      displayedDate: selectedDate,
    };

    try {
      const response = await fetch(`${PUBLIC_API_URL}/news/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newsData),
      });

      if (response.ok) {
        const data = await response.json();
        updateData();
        redirectTo("/admin");
        console.log("News created:", data);
      } else {
        const errorData = await response.json();
        console.error("Error creating news:", errorData);
      }
    } catch (error) {
      console.error("Internal error:", error);
    }
  };

  if (currentArticle === undefined) {
    const fetchArticle = async () => {
      try {
        const response = await fetch(`${PUBLIC_API_URL}/news/id/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch news data");
        }

        let newsData = await response.json();
        newsData.createdAt = new Date(newsData.createdAt.createdAt);

        setCurrentArticle(newsData);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchArticle();
  }

  const [articleTitle, setArticleTitle] = useState<string>(
    currentArticle.title
  );
  const [articleContent, setArticleContent] = useState<string>(
    currentArticle.content
  );
  const [articleImg, setArticleImg] = useState<string>(
    currentArticle.previewImage
  );

  const [selectedDate, setSelectedDate] = useState<string>(
    currentArticle.displayedDate
      ? formatDate(new Date(currentArticle.displayedDate))
      : formatDate(new Date(currentArticle.createdAt))
  );

  const handleDateChange = (newDate: string) => {
    setSelectedDate(newDate);
  };

  return (
    <div className="w-full flex flex-col md:flex-row mt-4 px-section pt-12 pb-12">
      <div className="flex flex-col w-full md:w-2/3">
        <h1 className="font-bold text-2xl text-start">
          {t("Edit")} <span className="text-blue">{t("news")}</span>
        </h1>
        <div className="mt-3" />
        <SettingsInput
          value={articleTitle}
          setValue={setArticleTitle}
          label="News title"
          placeholder="Enter news title..."
        />

        <div className="mt-3" />
        <SettingsInput
          value={articleImg}
          setValue={setArticleImg}
          label="News image URL"
          placeholder="Enter news image URL..."
        />

        {/* <div className="mt-3" />
        <SettingsInput
          isTextArea
          value={articleContent}
          setValue={setArticleContent}
          label="News content"
          placeholder="Enter news content..."
          additionalStyles="min-h-40"
        /> */}

        <div className="mt-3" />
        <p className="text-sm font-light mb-1">{t("News date")}</p>
        <input
          type="date"
          value={selectedDate}
          onChange={(e) => handleDateChange(e.target.value)}
          style={{ padding: "8px", margin: "5px 0", fontSize: "16px" }}
        />
        <div className="mt-3" />
        <p className="text-sm font-light mb-1">{t("News content")}</p>
        <ReactQuill
          value={articleContent}
          onChange={setArticleContent}
          placeholder="Write your news content here..."
          modules={{
            toolbar: [
              [{ header: "1" }, { header: "2" }, { font: [] }],
              [
                {
                  size: [
                    "10px",
                    "12px",
                    "14px",
                    "16px",
                    "18px",
                    "20px",
                    "24px",
                    "30px",
                  ],
                },
              ],
              [{ list: "ordered" }, { list: "bullet" }],
              ["bold", "italic", "underline", "strike"],
              [{ align: [] }],
              [{ color: [] }, { background: [] }],
              [{ script: "sub" }, { script: "super" }],
              ["blockquote", "code-block"],
              ["link", "image", "video"],
              ["clean"],
            ],
          }}
          className="custom-quill min-h-80 mb-10"
        />
      </div>
      <div className="flex flex-col w-full md:w-1/3 ml-0 md:ml-8 pt-16">
        <div className="shadow-sm py-10 cursor-pointer flex flex-col items-center justify-center rounded-xl">
          <img src={UploadImageIcon} alt="UploadImage" />
          <p
            className="text-center mt-2 w-2/3 text-zinc-400"
            style={{ fontSize: "14px" }}
          >
            {t("Add a cover photo to a news article")}
          </p>
        </div>
        <RectangularButton
          title="Save"
          additionalStyles="bg-blue mt-4"
          onClick={handlePublish}
        />
      </div>
    </div>
  );
};

export default EditArticlePage;

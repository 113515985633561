import React from "react";

const BLACK_LISTED_TAGS = ["<p>Share this article</p>"];

const RenderRichText: React.FC<{ richText: string, htmlRef: any }> = ({ richText, htmlRef }) => {
  function removeBlackListed(richText: string): string {
    let result: string = richText;

    for (let i = 0; i < BLACK_LISTED_TAGS.length; i++) {
      result = result.split(BLACK_LISTED_TAGS[i]).join("");
    }

    return result;
  }

  return (
    <div
      ref={htmlRef}
      className="rich_text ql-editor"
      dangerouslySetInnerHTML={{ __html: removeBlackListed(richText) }}
    />
  );
};

export default RenderRichText;

import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useGoogleLogin } from "@react-oauth/google";

import CrossIcon from "../../assets/common/cross.svg";
import Logo from "../../assets/common/logo.png";
import GoogleIcon from "../../assets/social_media/google.svg";
import AppleIcon from "../../assets/social_media/apple.svg";

import RectangularButton from "../inputs/rectangularButton";

const AuthEmail = ({
  email,
  setEmail,
  onClose,
  onNext,
  onSignUp,
  handleGoogle,
  handleApple,
}: any) => {
  const { t } = useTranslation();

  const loginGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfoResponse = await fetch(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        }
      );

      const userInfo = await userInfoResponse.json();

      handleGoogle({ tokenResponse, userInfo });
    },
    onError: (error) => {
      console.error("Login Failed:", error);
    },
  });

  return (
    <div>
      <button
        className="absolute top-8 right-8 text-gray-500 hover:text-gray-700"
        onClick={onClose}
      >
        <img width={15} height={15} src={CrossIcon} alt="CrossIcon" />
      </button>
      <div className="w-full flex flex-col items-center justify-center px-6">
        <img src={Logo} alt="Logo" width={80} height={80} />
        <h1 className="text-2xl font-bold text-olive">{t("Authorization")}</h1>
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={t("Enter your email...")}
          className="font-light px-2 py-2 rounded-md border border-gray-300 w-full mt-4"
          style={{ fontSize: "13px" }}
        />
        <div className="w-1/3 flex items-center justify-center mt-4">
          <RectangularButton title="Next" onClick={onNext} />
        </div>
        <div className="flex flex-row items-center justify-center w-full mt-4">
          <div className="w-1/3 bg-gray-400" style={{ height: "1px" }} />
          <span className="font-bold text-sm mx-4">{t("Or")}</span>
          <div className="w-1/3 bg-gray-400" style={{ height: "1px" }} />
        </div>
        <div className="w-full flex flex-row items-center justify-center mb-2 mt-8">
          <button
            className="rounded-full border-olive p-2.5"
            onClick={() => loginGoogle()}
          >
            <img src={GoogleIcon} alt="GoogleIcon" />
          </button>

          <button
            className="rounded-full border-olive p-1.5 ml-2"
            onClick={handleApple}
          >
            <img src={AppleIcon} alt="AppleIcon" />
          </button>
        </div>
        <p className="font-bold text-sm mx-4 text-center mt-4">
          {t("No account yet?")}{" "}
          <span
            className="text-olive underline cursor-pointer"
            onClick={onSignUp}
          >
            {t("Sign up")}
          </span>
        </p>
      </div>
    </div>
  );
};

export default AuthEmail;

import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../context/GlobalContext";
import useRedirect from "../../hooks/useRedirect";

import PlusIcon from "../../assets/admin/plus_blue.svg";
import FilterIcon from "../../assets/admin/filter.svg";
import SortIcon from "../../assets/admin/sorting.svg";
import ArrowIcon from "../../assets/admin/arrow_right_white.svg";
import ViewsIcon from "../../assets/admin/views.svg";
import LikesIcon from "../../assets/admin/likes.svg";
import CommentsIcon from "../../assets/admin/comments.svg";

import SearchInput from "../../components/inputs/searchInput";
import AdminPanelButton from "../../components/inputs/AdminPanelButton";
import AdminArticleCard from "../../components/cards/adminArticleCard";
import Popup from "../../components/inputs/popup";

const AdminMain = () => {
  const { t } = useTranslation();
  const globalContext = useContext(GlobalContext);
  const redirectTo = useRedirect();

  const {
    newsData,
    setNewsData,
    updateData,
    PUBLIC_API_URL,
    updateAdminData,
    adminNewsData,
    setAdminNewsData,
  } = globalContext;

  const [searchInput, setSearchInput] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedIds, setSelectedIds] = useState<any>([]);
  // const [newsData, setNewsData] = useState([
  //   {
  //     _id: "123d123",
  //     title: "VivoOlio: The best recipes for dishes with olives.",
  //     createdAt: new Date(),
  //     views: 250,
  //     likes: 132,
  //     comments: ["213312"],
  //     author: {
  //       name: "VivoOlio",
  //       image:
  //         "https://beautyrepublicfdl.com/wp-content/uploads/2020/06/placeholder-image.jpg",
  //     },
  //     previewImage:
  //       "https://beautyrepublicfdl.com/wp-content/uploads/2020/06/placeholder-image.jpg",
  //     content: "text_1 text_1 text_1 text_1 text_1 text_1 text_1",
  //   },
  //   {
  //     _id: "dasdaas3",
  //     title: "Some othe articel",
  //     createdAt: new Date(),
  //     views: 250,
  //     likes: 13,
  //     comments: ["213312", "31241sadasdasd"],
  //     author: {
  //       name: "VivoOlio",
  //       image:
  //         "https://beautyrepublicfdl.com/wp-content/uploads/2020/06/placeholder-image.jpg",
  //     },
  //     previewImage:
  //       "https://beautyrepublicfdl.com/wp-content/uploads/2020/06/placeholder-image.jpg",
  //     content: "text_2 text_2 text_2 text_2 text_2 text_2 text_2",
  //   },
  //   {
  //     _id: "1a23123",
  //     title: "Lorem ipsum",
  //     createdAt: new Date(),
  //     views: 2250,
  //     likes: 2,
  //     comments: ["213312", "31241sadasdasd", "fsdfasdjoaosdj"],
  //     author: {
  //       name: "VivoOlio",
  //       image:
  //         "https://beautyrepublicfdl.com/wp-content/uploads/2020/06/placeholder-image.jpg",
  //     },
  //     previewImage:
  //       "https://beautyrepublicfdl.com/wp-content/uploads/2020/06/placeholder-image.jpg",
  //     content: "text_3 text_3 text_3 text_3 text_3 text_3 text_3",
  //   },
  //   {
  //     _id: "12312c3",
  //     title: "Lorem lorem",
  //     createdAt: new Date(4324234234),
  //     views: 4250,
  //     likes: 1132,
  //     comments: ["213312", "31241sadasdasd", "fsdfasdjoaosdj"],
  //     author: {
  //       name: "VivoOlio",
  //       image:
  //         "https://beautyrepublicfdl.com/wp-content/uploads/2020/06/placeholder-image.jpg",
  //     },
  //     previewImage:
  //       "https://beautyrepublicfdl.com/wp-content/uploads/2020/06/placeholder-image.jpg",
  //     content: "text_4 text_4 text_4 text_4 text_4 text_4 text_4",
  //   },
  // ]);

  const itemsPerPage = 8;

  const totalPages = Math.ceil(adminNewsData.length / itemsPerPage);
  const paginatedData = adminNewsData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    setCurrentPage(1);
  }, []);

  const handleDelete = async () => {
    const token = localStorage.getItem("token");

    const response = await fetch(`${PUBLIC_API_URL}/news`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ids: selectedIds }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Error deleting news articles:", errorData);
      return;
    }

    const result = await response.json();
    console.log(result);
    setCurrentPage(1);
    updateAdminData();
  };

  const filteredData = adminNewsData.filter((article: any) =>
    article.title.toLowerCase().startsWith(searchInput.toLowerCase())
  );

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const sortByViews = (
    data: typeof adminNewsData = adminNewsData,
    order: string = "desc"
  ) => {
    const sortedData = [...data].sort((a, b) => {
      if (order === "asc") {
        return a.views - b.views;
      } else {
        return b.views - a.views;
      }
    });

    setAdminNewsData(sortedData);
    return sortedData;
  };

  const sortByLikes = (
    data: typeof adminNewsData = adminNewsData,
    order: string = "desc"
  ) => {
    const sortedData = [...data].sort((a, b) => {
      if (order === "asc") {
        return a.likes - b.likes;
      } else {
        return b.likes - a.likes;
      }
    });

    setAdminNewsData(sortedData);
    return sortedData;
  };

  const sortByComments = (
    data: typeof adminNewsData = adminNewsData,
    order: string = "desc"
  ) => {
    const sortedData = [...data].sort((a, b) => {
      if (order === "asc") {
        return a.comments.length - b.comments.length;
      } else {
        return b.comments.length - a.comments.length;
      }
    });

    setAdminNewsData(sortedData);
    return sortedData;
  };

  const sortByDate = (
    data: typeof adminNewsData = adminNewsData,
    order: string = "desc"
  ) => {
    const sortedData = [...data].sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();

      if (order === "asc") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    setAdminNewsData(sortedData);
    return sortedData;
  };

  const toggleArticleSelection = (articleId: any) => {
    setSelectedIds((prevSelectedArticles: any) => {
      if (prevSelectedArticles.includes(articleId)) {
        return prevSelectedArticles.filter((id: any) => id !== articleId);
      } else {
        return [...prevSelectedArticles, articleId];
      }
    });
  };

  useEffect(() => {
    updateAdminData();
  }, []);

  return (
    <div className="px-section w-full pt-16 pb-8">
      <p className="font-bold text-2xl text-start">
        {t("Admin")} <span className="text-blue">{t("panel")}</span>
      </p>
      <div className="flex flex-row items-center justify-between w-full my-6">
        <div className="w-1/3">
          <SearchInput value={searchInput} setValue={setSearchInput} />
        </div>
        <div className="flex flex-row items-center justify-end">
          <AdminPanelButton
            icon={PlusIcon}
            title="Publish news"
            onClick={() => redirectTo("/admin/create-article")}
          />
          <AdminPanelButton
            additionalTitleStyles=""
            title="Delete"
            onClick={handleDelete}
            isDisabled={selectedIds.length === 0}
          />
          <Popup
            buttons={[
              {
                title: "Newest",
                onClick: () => {
                  setAdminNewsData(sortByDate(adminNewsData, "desc"));
                },
              },
              {
                title: "Oldest",
                onClick: () => {
                  setAdminNewsData(sortByDate(adminNewsData, "asc"));
                },
              },
            ]}
          >
            <AdminPanelButton
              icon={SortIcon}
              title="Sorting"
              additionalTitleStyles="!text-black"
            />
          </Popup>

          <Popup
            buttons={[
              {
                title: "Views",
                icon: ViewsIcon,
                onClick: sortByViews,
              },
              {
                title: "Likes",
                icon: LikesIcon,
                onClick: sortByLikes,
              },
              {
                title: "Comments",
                icon: CommentsIcon,
                onClick: sortByComments,
              },
            ]}
          >
            <AdminPanelButton
              icon={FilterIcon}
              title="Filter"
              additionalTitleStyles="!text-black"
            />
          </Popup>
        </div>
      </div>
      <div className="w-full flex flex-row items-center px-4 mb-4">
        <h3 className="w-3/6">{t("News")}</h3>
        <div className="flex flex-row items-center w-full">
          <h3 className="w-full text-center">{t("Date")}</h3>
          <h3 className="w-full text-center">{t("Views")}</h3>
          <h3 className="w-full text-center">{t("Likes")}</h3>
          <h3 className="w-full text-center">{t("Published")}</h3>
          <div className="w-full text-center" />
        </div>
      </div>
      {searchInput !== ""
        ? filteredData.map((article: any, index: number) => (
            <div className="pb-2" key={index}>
              <AdminArticleCard
                article={article}
                isSelected={selectedIds.includes(article._id)}
                toggleSelect={() => toggleArticleSelection(article._id)}
              />
            </div>
          ))
        : paginatedData.map((article: any, index: number) => (
            <div className="pb-2" key={index}>
              <AdminArticleCard
                article={article}
                isSelected={selectedIds.includes(article._id)}
                toggleSelect={() => toggleArticleSelection(article._id)}
              />
            </div>
          ))}

      <div className="flex justify-center items-center mt-6">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className={`px-3 bg-blue w-8 h-8 rounded-full flex items-center jutify-center ${
            currentPage === 1 ? "!bg-zinc-400 cursor-not-allowed" : ""
          }`}
        >
          <img
            className="rotate-180 h-full w-full"
            src={ArrowIcon}
            alt="ArrowLeft"
          />
        </button>

        <span className="text-lg mx-2">
          {currentPage} {t("of")} {totalPages}
        </span>

        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className={`px-3 bg-blue w-8 h-8 rounded-full flex items-center jutify-center ${
            currentPage === totalPages ? "!bg-zinc-400 cursor-not-allowed" : ""
          }`}
        >
          <img className="h-full w-full" src={ArrowIcon} alt="ArrowLeft" />
        </button>
      </div>
    </div>
  );
};

export default AdminMain;

import React, { useContext, useEffect } from "react";
import { GlobalContext } from "./context/GlobalContext";
import { Routes, Route } from "react-router-dom";

import { useTranslation } from "react-i18next";

import "./App.css";
import "./i18n";

import Header from "./components/common/header";
import Footer from "./components/common/footer";

import MainPage from "./pages/main";
import ArticlePage from "./pages/article";
import AdminPage from "./pages/admin/admin";

function App() {
  const globalContext = useContext(GlobalContext);
  const { t } = useTranslation();

  if (!globalContext) {
    throw new Error("GlobalContext must be used within a GlobalProvider");
  }

  const {
    PUBLIC_API_URL,
    setOtherNewsData,
    setRecentNews,
    setNewsFeed,
    setNewsData,
    setUser,
    setIsAuthenticated,
  } = globalContext;

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(`${PUBLIC_API_URL}/news/published`);
        if (!response.ok) {
          throw new Error("Failed to fetch news data");
        }

        let newsData = await response.json();
        newsData = newsData.map((item: any) => ({
          ...item,
          createdAt: new Date(item.createdAt),
        }));

        const sortedNews = newsData.sort(
          (a: any, b: any) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );

        setNewsData(sortedNews);

        const recent = sortedNews.slice(0, 4);
        setRecentNews(recent);
        const feed = sortedNews.slice(4);
        setNewsFeed(feed);
        const otherNews = newsData.slice(-3);
        setOtherNewsData(otherNews);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");

      if (token) {
        try {
          const response = await fetch(`${PUBLIC_API_URL}/users/token`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            throw new Error("Failed to fetch user data");
          }

          const data = await response.json();
          console.log("Fetched user data:", data);

          setUser(data);
          setIsAuthenticated(true);
        } catch (err) {
          console.error("Error fetching user data:", err);
        }
      }
    };

    fetchUserData();
  }, []);

  return (
    <div className="flex flex-col justify-between min-h-screen scrollbar-hidden">
      <Header />
      <Routes>
        <Route index element={<MainPage />} />
        <Route path="article/:id" element={<ArticlePage />} />
        <Route path="admin/*" element={<AdminPage />} />

        <Route path="*" element={<div>404: Route not Found</div>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
